import React from 'react';
import { Box, Button, Divider, FormControl, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useState } from 'react';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },

    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            height: "fit-content",
            "& .infoCard": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                boxShadow: "-1px 3px 5px 1px #e3e3e3",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    '& p': {
                        color: "#797979"
                    }
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function OverlayList() {
    const [mediaType, setMediaType] = useState('add')
    const items = [
        { label: 'Image', },
        { label: 'Weather', },
        { label: 'Template 1 Column', },
        { label: 'Template 2 Column', },
    ];
    const TileData = [
        { label: 'Test Customer', type: "Image", animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" }, // Replace with image path if needed
        { label: 'Test Customer', type: "Weather", animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', type: "Template 1 Column", animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', type: "Template 2 Column", animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
    ];
    let navigate = useNavigate();
    const handleChangeMediaType = (e) => {
        setMediaType(e.target.value)
        navigate(`/overlay/${e.target.value}/Add`)
    }
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black"> Overlay List</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">

                        <FormControl>
                            <Select value={mediaType}
                                onChange={handleChangeMediaType}
                            >
                                <MenuItem sx={{ fontWeight: "600 !important" }} value="add">Add</MenuItem>
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {TileData?.map((e, i) => (<Box className='infoCard' key={i} sx={{ cursor: "pointer" }}  >
                            <Box className="themeChanger">
                                <Grid container p={1.4} spacing={1} alignItems={"center"}>
                                    <Grid item textAlign={"end"} xs={4}>
                                        <Box sx={{ height: "80px", borderRadius: "10px", overflow: "hidden" }}>
                                            <img src={require('../../Assets/loginBackgroundImg/img1.png')} alt={'img'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant='subtitle2'>Image.jpg</Typography>
                                        <Typography variant='body2'>Test Customer</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle2'>{e.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Box sx={{ display: "flex", justifyContent: "end", gap: 1, alignItems: "center", padding: 1.4 }}>
                                    <Button startIcon={<DeleteOutlinedIcon />} variant='contained' color='error'>Delete</Button>
                                    <Button onClick={() => { navigate(`/overlay/${e.type}/Edit`) }} startIcon={<BorderColorIcon />} variant='contained' color='success' >Edit</Button>
                                </Box>
                            </Box>
                        </Box>))}
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}