import React, { useEffect } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
// import AuthService from '../Services/AuthService';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .mainContainer': {
        // backgroundColor: '#f3f9f3',
        overflow: "hidden",
        // display: "flex",
        // justifyContent: "center",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            // gap: "20px",
            alignItems: "center",
            width: "100%",
            padding: "10px",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function Customers() {
    let navigate = useNavigate();

    const items = [
        { label: 'Test Customer', }, // Replace with image path if needed
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
    ];
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">Customer List</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton>
                                <ControlPointIcon sx={{ color: "blue" }} />
                            </IconButton>
                            <Typography variant="body2" color="blue">Add Customer</Typography>
                        </Box>

                    </Box>
                    <Divider />
                    <Box className="content">
                        {items?.map((e, i) => (<Box className='infoCard' sx={{ cursor: "pointer" }}  >
                            <Box className="themeChanger">
                                <Box>
                                    <Typography variant='body2'>{e.label}</Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={() => { navigate('/edit-customer') }}>
                                        <BorderColorOutlinedIcon />
                                    </IconButton>
                                    <IconButton>
                                        <DeleteForeverOutlinedIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>))}

                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}