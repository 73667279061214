import React from 'react';
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MyQuillEditor from '../../Components/TextEditor';
import { useState } from 'react';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },

    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            height: "calc(100vh - 167px)",
            overflowY: "auto",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function AddMedia() {
    const [description, setDescription] = useState('')
    let navigate = useNavigate();
    const { type, to } = useParams()

    //Handel Change
    const getTextFromEditor = (value) => {
        setDescription(value)
    }
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">{to} {type}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant='subtitle1'>Name *</Typography>
                        <TextField sx={{ marginBottom: "5px" }} placeholder='Name' />
                        <Typography variant='subtitle1'>Sorting Order </Typography>
                        <TextField sx={{ marginBottom: "5px" }} placeholder='Sorting Order' />
                        <Typography variant='subtitle1'>Duration </Typography>
                        <TextField sx={{ marginBottom: "5px" }} placeholder='Duration' />
                        <Typography variant='subtitle1'>Description </Typography>
                        <TextField sx={{ marginBottom: "5px" }} />
                        <Typography variant='subtitle1'>{type} *</Typography>
                        {type === "video" ?
                            <TextField sx={{ marginBottom: "5px" }} type='file' inputProps={{
                                accept: "video/*", // Accepts all video file types
                            }} />
                            : type === "HTML Content" ?
                                <MyQuillEditor value={description} getTextFromEditor={getTextFromEditor} from={to?.toLowerCase()} />
                                : type === "Web Url" ?
                                    <TextField sx={{ marginBottom: "5px" }} type='text' />
                                    :
                                    <TextField sx={{ marginBottom: "5px" }} type='file' />
                        }

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", padding: "10px", gap: 1 }}>
                        <Button variant='contained' color='error'>Cancel</Button>
                        <Button variant='contained' color='success'>Save</Button>
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}