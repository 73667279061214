import React from 'react';
import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
// import AuthService from '../Services/AuthService';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SellIcon from '@mui/icons-material/Sell';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .mainContainer': {
        // backgroundColor: '#f3f9f3',
        overflow: "hidden",
        // display: "flex",
        // justifyContent: "center",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            gap: "20px",
            alignItems: "center",
            width: "100%",
            padding: "10px",
            // height: "calc(100vh - 170px)",
            "& .infoCard": {
                // padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                // border: "1px solid #ccc",
                boxShadow: "-1px 3px 5px 1px #e3e3e3",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "space-between"
                    '& p': {
                        color: "#797979"
                    }
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function ScreenList() {
    let navigate = useNavigate();

    const items = [
        { label: 'Test Customer', animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
        { label: 'Test Customer', animation: "fade", position: "bottom", description: "loram  ispum xospo thufo gama fchalbin" },
    ];
    const handleAddScreen = () => {
        navigate("/screen-add/112")
    }
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">Screen List</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton>
                                <ControlPointIcon sx={{ color: "blue" }} />
                            </IconButton>
                            <Typography onClick={handleAddScreen} variant="body2" color="blue">Add Screen</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        {items?.map((e, i) => (<Box className='infoCard' key={i} sx={{ cursor: "pointer" }}  >
                            <Box className="themeChanger">
                                <Grid container p={1.4}>
                                    <Grid container alignItems={"center"} gap={1} item xs={6}>
                                        <SellIcon sx={{ transform: "rotateY(180deg)", fontSize: "16px", color: "#007f7c" }} />
                                        <Typography sx={{ color: "#007f7c", fontWeight: 600 }} variant='subtitle2'>Test123</Typography>
                                    </Grid>
                                    <Grid container item alignItems={"center"} justifyContent={"end"} gap={1} xs={6}>
                                        <Typography variant='subtitle2'>Bottom</Typography>
                                        <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                                        <Typography variant='subtitle2'>Fade</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle2'>{e.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Box sx={{ display: "flex", justifyContent: "end", gap: 1, alignItems: "center", padding: 1.4 }}>
                                    <Button startIcon={<DeleteOutlinedIcon />} variant='contained' color='error'>Delete</Button>
                                    <Button onClick={() => { navigate('/screen-edit') }} startIcon={<BorderColorIcon />} variant='contained' color='success' >Edit</Button>
                                </Box>
                            </Box>
                        </Box>))}
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}