import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // For the snow theme
import 'react-quill/dist/quill.bubble.css'; // For the bubble theme
import { styled } from "@mui/material/styles";
import CodeIcon from '@mui/icons-material/Code';
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    min-height: 300px;
  }

  .ql-toolbar {
    border-bottom: 1px solid #ccc;
  }
`;
const MyQuillEditor = ({ getTextFromEditor, isSubmitted = false, from = "add", value = "" }) => {
    const [editorHtml, setEditorHtml] = useState('');
    const [isHtmlMode, setIsHtmlMode] = useState(false);

    useEffect(() => {
        if (from === "detail" && isSubmitted) {
            setEditorHtml('');
        }
    }, [isSubmitted])
    useEffect(() => {
        setEditorHtml(value);  // Update editor if new value is passed from parent
    }, [value]);

    const handleChange = (html) => {
        setEditorHtml(html);
        getTextFromEditor(html)
    };
    const toggleHtmlMode = () => {
        setEditorHtml('')
        setIsHtmlMode(!isHtmlMode);
    };
    return (
        <div style={{ width: "100%", position: "relative" }}>
            {isHtmlMode ? (<>
                <Box sx={{ height: "40px", border: "1px solid #ccc", borderBottom: "none", }}></Box>
                <textarea
                    value={editorHtml}
                    onChange={(e) => handleChange(e.target.value)}
                    style={{ width: "100%", height: "300px", border: "1px solid #ccc", outline: "none" }}
                />
            </>
            ) : (
                <StyledQuill
                    onChange={handleChange}
                    value={editorHtml}
                    modules={MyQuillEditor.modules}
                    formats={MyQuillEditor.formats}
                    bounds={'.app'}
                    placeholder=""
                    dangerouslySetInnerHTML={{ __html: editorHtml }} // Inject HTML content
                />
            )}
            <IconButton sx={{ position: "absolute", top: 4, right: 4 }} onClick={toggleHtmlMode}>
                <CodeIcon />
            </IconButton>

        </div>
    );
};

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
MyQuillEditor.modules = {
    toolbar: [

        [{ 'size': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
    },
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
MyQuillEditor.formats = [
    'size',
    'list', 'indent',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'link', 'image', 'video',
];

/* 
 * PropType validation
 */
MyQuillEditor.propTypes = {
    placeholder: PropTypes.string,
};

/* 
 * Export the component
 */
export default MyQuillEditor;
