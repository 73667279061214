import React from 'react';
import { Box, Button, Divider, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import BarcodeGenerator from '../../Components/BarcodeGenerator';
import { useState } from 'react';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },
    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },

    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            height: "calc(100vh - 170px)",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
            "& .checkBoxes": {
                padding: "0 10px 0 0", borderRadius: "5px", marginLeft: "-8px"
            }
        },

    }
}))

export default function EditScreen() {
    const [formFields, setFormFields] = useState({
        tag: "Test123"
    })
    let navigate = useNavigate();
    let { id } = useParams()
    const items = [
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
    ];
    const handleChange = (e) => {
        setFormFields({ ...formFields, [e.target.name]: e.target.value })
    }
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">{id ? "Add Screen" : "Edit Screen"}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant='subtitle1'>Tag *</Typography>
                        <TextField sx={{ marginBottom: "5px" }} onChange={handleChange} name='tag' value={formFields?.tag || ''} placeholder='Tag' />
                        <Typography variant='subtitle1'>Animation *</Typography>
                        <FormControl>
                            <Select onChange={handleChange} name='animation' value={formFields?.animations || ''} >
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant='subtitle1'>Position</Typography>
                        <FormControl>
                            <Select onChange={handleChange} name="position" value={formFields?.position || ''} >
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <Typography variant='subtitle1'>Description </Typography>
                        <TextField sx={{ marginBottom: "5px" }} onChange={handleChange} name='description' value={formFields?.description || ''} multiline minRows={3} maxRows={5} />
                        <Box>
                            <Typography variant='subtitle1'>Split Screens </Typography>
                            <FormControlLabel className='checkBoxes' value="Kiosks" control={<Radio checked={true} />} label={<Typography variant='body2'>Kiosks</Typography>} />
                            <FormControlLabel className='checkBoxes' value="TVs" control={<Radio />} label={<Typography variant='body2'>TVs</Typography>} />
                            <FormControlLabel className='checkBoxes' value="Room Signs" control={<Radio />} label={<Typography variant='body2'>Room Signs</Typography>} />


                        </Box>
                        <Box>
                            <BarcodeGenerator value={formFields?.tag} />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", padding: "10px", gap: 1 }}>
                        <Button variant='contained' color='error'>Cancel</Button>
                        <Button variant='contained' color='success'>Save</Button>
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}