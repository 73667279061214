import React from 'react';
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },
    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            height: "calc(100vh - 167px)",
            overflowY: "auto",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function AddMedia() {
    let navigate = useNavigate();
    const { type, to } = useParams()



    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">{to} {type}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant='subtitle1'>Sorting Order *</Typography>
                        <TextField sx={{ marginBottom: "5px" }} placeholder='Sorting Order' />
                        <Typography variant='subtitle1'>Duration * </Typography>
                        <TextField sx={{ marginBottom: "5px" }} placeholder='Duration' />


                        {type === "Weather" ?
                            <>
                                <Typography variant='subtitle1'>City *</Typography>
                                <TextField sx={{ marginBottom: "5px" }} placeholder='City' type='text' />
                            </>
                            : type === "Image" ?
                                <>
                                    <Typography variant='subtitle1'>Image* </Typography>
                                    <TextField sx={{ marginBottom: "5px" }} type='file' />
                                </> :
                                <>
                                    <Typography variant='subtitle1'>Heading *</Typography>
                                    <TextField sx={{ marginBottom: "5px" }} placeholder='Heading' type='text' />
                                    <Typography variant='subtitle1'>Description *</Typography>
                                    <TextField sx={{ marginBottom: "5px" }} multiline minRows={2} maxRows={4} placeholder='Description' />
                                    <Typography variant='subtitle1'>Image </Typography>
                                    <TextField sx={{ marginBottom: "5px" }} type='file' />
                                    {type === "Template 2 Column" && <>
                                        <Typography variant='subtitle1'>Heading2 *</Typography>
                                        <TextField sx={{ marginBottom: "5px" }} placeholder='Heading2' type='text' />
                                        <Typography variant='subtitle1'>Description2 *</Typography>
                                        <TextField sx={{ marginBottom: "5px" }} multiline minRows={2} maxRows={4} placeholder='Description2' />
                                        <Typography variant='subtitle1'>Image2 *</Typography>
                                        <TextField sx={{ marginBottom: "5px" }} type='file' />
                                    </>}

                                </>


                        }

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", padding: "10px", gap: 1 }}>
                        <Button variant='contained' color='error'>Cancel</Button>
                        <Button variant='contained' color='success'>Save</Button>
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}