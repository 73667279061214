import { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .video-container": {
        width: "100%",
        height: "100%",
    },
    "& .video-container video": {
        width: '100%',
        height: "100%",
        objectFit: "fit-content",
        display: "inline-block",
        opacity: .4
    },
    "& .scan-box": {
        position: 'absolute',
        top: '20%',
        left: "20%",
        width: '60%',
        height: '60%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        zIndex: 2,
        background: "transparent",
        backdropFilter: "brightness(2.4)",
        [theme.breakpoints.down('sm')]: {
            top: '30%',
            left: "13%",
            width: '72%',
            height: '40%',
        },
        [theme.breakpoints.up('sm')]: {
            top: '32%',
            left: "13%",
            width: '72%',
            height: '36%',
        },
        [theme.breakpoints.up('md')]: {
            top: '20%',
            left: "20%",
            width: '60%',
            height: '60%',
        },
    },
}));

const QuaggaBarcodeScanner = ({ selectedFormats, flipCamera, zoom, focusMode, applySetting, isBeepAllow, getSeniorIdByTag, handleAudio, isSettingChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const isScan = useRef(true);
    const videoRef = useRef(null);

    useEffect(() => {
        const formatHints = selectedFormats?.map((format) => {
            switch (format.toLowerCase()) {
                case "code_128":
                    return "code_128_reader";
                case "code_39":
                    return "code_39_reader";
                case "code_93":
                    return "code_93_reader";
                case "ean_13":
                    return "ean_reader";
                case "upc_a":
                    return "upc_reader";
                case "codabar":
                    return "codabar_reader";
                default:
                    return null;
            }
        }).filter(Boolean);

        Quagga.init({
            inputStream: {
                type: "LiveStream",
                target: videoRef.current,
                constraints: {
                    facingMode: flipCamera ? 'environment' : 'user',
                    width: 1280,
                    height: 720,
                    ...(applySetting && { zoom, focusMode }),
                },
                area: { // defines the area of the video feed that will be analyzed
                    top: "0%",    // top offset
                    right: "0%",  // right offset
                    left: "0%",   // left offset
                    bottom: "0%"  // bottom offset
                }
            },
            decoder: {
                readers: formatHints,
            },
            locate: true,
        }, (err) => {
            if (err) {
                console.error("Quagga initialization failed:", err);
                return;
            }
            Quagga.start();
            setIsLoading(false);
        });

        Quagga.onDetected((result) => {
            if (isScan.current) {
                isScan.current = false;
                if (isBeepAllow) handleAudio();
                const barcodeText = result.codeResult.code;
                getSeniorIdByTag(barcodeText);
                setTimeout(() => {
                    isScan.current = true;
                    setIsLoading(false);
                }, 2000);
            }
        });

        Quagga.onProcessed(() => {
            const canvas = document.querySelector('canvas');
            if (canvas) {
                const context = canvas.getContext('2d', { willReadFrequently: true });
            }
        });

        return () => {
            Quagga.stop();
        };
    }, [applySetting, flipCamera, isSettingChange]);

    return (
        <Root sx={{ position: 'relative', width: '100%', height: '100%', }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    // backgroundColor: 'rgba(0, 0, 0, 0.4)', // Background with opacity
                    zIndex: 1,
                }}
            />
            <Box className="video-container" ref={videoRef}>
                {isLoading && <p>Loading scanner...</p>}
            </Box>
            <Box className="scan-box" >
                {/* Borders */}
                <Box sx={{ position: 'absolute', top: 0, width: '80%', height: 2, backgroundColor: 'rgba(255, 255, 255, 0.4)' }} />
                <Box sx={{ position: 'absolute', bottom: 0, width: '80%', height: 2, backgroundColor: 'rgba(255, 255, 255, 0.4)' }} />
                <Box sx={{ position: 'absolute', left: 0, height: '80%', width: 2, backgroundColor: 'rgba(255, 255, 255, 0.4)' }} />
                <Box sx={{ position: 'absolute', right: 0, height: '80%', width: 2, backgroundColor: 'rgba(255, 255, 255, 0.4)' }} />

                {/* Corner indicators */}
                <Box sx={{ position: 'absolute', top: 0, left: 0, width: 20, height: 20, borderTop: '3px solid white', borderLeft: '3px solid white', }} />
                <Box sx={{ position: 'absolute', top: 0, right: 0, width: 20, height: 20, borderTop: '3px solid white', borderRight: '3px solid white', }} />
                <Box sx={{ position: 'absolute', bottom: 0, left: 0, width: 20, height: 20, borderBottom: '3px solid white', borderLeft: '3px solid white', }} />
                <Box sx={{ position: 'absolute', bottom: 0, right: 0, width: 20, height: 20, borderBottom: '3px solid white', borderRight: '3px solid white', }} />
            </Box>
        </Root>
    );
};

export default QuaggaBarcodeScanner;
