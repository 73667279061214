import React, { useEffect } from 'react';
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
// import AuthService from '../Services/AuthService';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },

    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        // backgroundColor: '#f3f9f3',
        overflow: "hidden",
        // display: "flex",
        // justifyContent: "center",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            // gap: "20px",
            // alignItems: "center",
            width: "100%",
            padding: "10px",
            height: "calc(100vh - 170px)",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function AddSchedule() {
    let navigate = useNavigate();

    const items = [
        { label: 'Test Customer', }, // Replace with image path if needed
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
    ];
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black"> Add Schedule</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant='subtitle1'> Select Media *</Typography>
                        <FormControl>
                            <Select value="Test Customer" >
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant='subtitle1'>  Screen</Typography>
                        <FormControl>
                            <Select value="Test Customer" >
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant='subtitle1'>Start Date *</Typography>
                        <TextField type='date' sx={{ marginBottom: "5px" }} />
                        <TextField type='time' sx={{ marginBottom: "5px" }} />
                        <Typography variant='subtitle1'>End Date *</Typography>
                        <TextField type='date' sx={{ marginBottom: "5px" }} />
                        <TextField type='time' sx={{ marginBottom: "5px" }} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", padding: "10px", gap: 1 }}>
                        <Button variant='contained' color='error'>Cancel</Button>
                        <Button variant='contained' color='success'>Save</Button>
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}