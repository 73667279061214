import React, { useEffect } from 'react';
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
// import AuthService from '../Services/AuthService';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },
    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function Schedule() {
    let navigate = useNavigate();
    const items = [
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
        { label: 'Test Customer', },
    ];
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">Schedule</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant='subtitle1'>Screen</Typography>
                        <FormControl>
                            <Select value="Test Customer" >
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button sx={{ marginY: 2 }} variant='contained' onClick={() => { navigate('/schedule-add') }} fullWidth color='secondary'>Add Screen</Button>
                        <Box sx={{
                            "& .react-calendar": {
                                width: "100%",
                                border: "none",
                                "& button": {
                                    fontFamily: "system-ui"
                                },
                                "& .react-calendar__tile--active": {
                                    borderRadius: "10px",
                                },
                                "& .react-calendar__month-view__weekdays": {
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                    "& abbr": {
                                        textDecoration: "none",
                                        fontWeight: "500",
                                    }
                                }
                            }
                        }}>
                            <Calendar width={"100%"} value={new Date()} />
                        </Box>
                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}