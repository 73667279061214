import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .mainContainer': {

        backgroundColor: '#f3f9f3',
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        height: "100vh ",
        overflowY: "auto",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            gap: "20px",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            width: "100%",
            "& .text": {

                "& .header": {
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#000",
                }
            },
            '& img[alt="heartpng"] ': {
                display: "flex",
                width: 60,
                animation: " heartbeat 1.2s infinite",
            },

            "@keyframes heartbeat": {
                "0%": {
                    transform: "scale(1)",
                },
                "25%": {
                    transform: "scale(0.9)",
                },
                "60%": {
                    transform: "scale(1.1)",
                },
                "100%": {
                    transform: "scale(1)",
                }
            }
        },

    }
}))

export default function Home() {
    let navigate = useNavigate();
    const handleNavigate = (name) => {

        switch (name) {
            case 'Customer':
                navigate('/customers')
                break;
            case 'Screen':
                navigate('/screen-list')
                break;
            case 'Media':
                navigate('/media-list')
                break;
            case 'Overlay':
                navigate('/overlay-list')
                break;
            case 'Schedule':
                navigate('/schedule')
                break;
            case 'Scanner':
                navigate('/schedule-scanner')
                break;
            default:
                navigate('/screens')
                break;
        }
    }
    const items = [
        { label: 'Screen', icon: require('../../Assets/Scheduler/Screen.png') },
        { label: 'Media', icon: require('../../Assets/Scheduler/Media.png') },
        { label: 'Overlay', icon: require('../../Assets/Scheduler/Overlay.png') },
        { label: 'Schedule', icon: require('../../Assets/Scheduler/Schedule.png') },
        { label: 'Scanner', icon: require('../../Assets/images/scan-icon.png') },
    ];
    return (
        <Root p={2} >
            <Box className="mainContainer">
                <Box className="content">
                    <Box > <img src={`${window.location.origin}/images/logo.png`} alt="heartpng" /> </Box>
                    <Box className="text">
                        <Typography className='header' variant="body2">  Manage Screens</Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            gap: '16px',
                            padding: '16px',
                            backgroundColor: '#f3f9f3',
                            width: '100%',
                        }}
                    >
                        {items.map((item, index) => (
                            <Box
                                onClick={() => { handleNavigate(item.label) }}
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: index === 4 ? "95.5%" : '46.5%', // Full width for the last item
                                    height: '150px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                    backgroundColor: '#f8f8f8',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s ease',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <img
                                    src={item.icon}
                                    alt={item.label}
                                    style={{
                                        width: '48px',
                                        height: '48px',
                                        marginBottom: '8px',
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        color: '#333',
                                    }}
                                >
                                    {item.label}
                                </span>
                            </Box>
                        ))}
                    </Box>
                </Box>

            </Box>
        </Root >
    )
}