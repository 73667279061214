import React from 'react'
import { useState } from 'react'
import { styled } from '@mui/material/styles';
import QuaggaBarcodeScanner from '../../Components/Quagga'
import DefaultLayout from '../../Layouts/DefaultLayout'
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Root = styled(Box)(({ theme }) => ({
    height: 'calc(100vh - 50px)',
    backgroundColor: "#000",
    marginTop: "50px",
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))
function Scanner() {
    const [selectedFormats, setSelectedFormats] = useState(["code_128"])
    const navigate = useNavigate()
    const getResponse = (data) => {
        console.log(data)
        navigate('/media-list')
    }
    const handleAudio = async () => {
        var audio = new Audio(require('../../Assets/audio/scanner-beep.mp3'));
        await audio.play();
    }
    return (
        <DefaultLayout>
            <Root>
                <QuaggaBarcodeScanner
                    selectedFormats={selectedFormats}
                    flipCamera={true}
                    zoom={1}
                    focusMode={"continuous"}
                    applySetting={true}
                    isBeepAllow={true}
                    getSeniorIdByTag={getResponse}
                    handleAudio={handleAudio}
                />
            </Root>
        </DefaultLayout>
    )
}

export default Scanner