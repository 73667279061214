import React, { useState } from 'react';
import Barcode from 'react-barcode';

const BarcodeGenerator = ({ value = null }) => {
    return (
        <div style={{ textAlign: 'center', }}>
            {value &&
                <Barcode
                    value={value}
                    width={2}
                    height={50}
                    displayValue={true}
                    format="CODE128"
                />
            }

        </div>
    );
};

export default BarcodeGenerator;
