import React from 'react';
import { Box, Divider, FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MuiOutlinedInput-root": {
        fontWeight: 600,
    },
    "& .MuiSelect-select": {
        padding: "15px !important",
        "& .MuiInputBase-input": {
            padding: "15px !important",
        },
    },

    "& .MuiFormControl-root": {
        marginBottom: "5px"
    },
    '& .mainContainer': {
        overflow: "hidden",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            width: "100%",
            padding: "10px",
            height: "fit-content",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },
        },

    }
}))

export default function MediaList() {
    const [mediaType, setMediaType] = useState('add')

    const items = [
        { label: 'Image', },
        { label: 'Video', },
        { label: 'HTML Content', },
        { label: 'Web Url', },
    ];
    const TileData = [
        { name: "Village Image", media: require('../../Assets/loginBackgroundImg/img1.png'), type: "Image" },
        { name: "Video", media: require('../../Assets/loginBackgroundImg/img1.png'), type: "Video" },
        { name: " HTML Content", media: require('../../Assets/loginBackgroundImg/img1.png'), type: "HTML Content" },
        { name: "Wikipedia", media: require('../../Assets/loginBackgroundImg/img1.png'), type: "Web Url" },
    ]
    let navigate = useNavigate();
    const handleChangeMediaType = (e) => {
        setMediaType(e.target.value)
        navigate(`/media/${e.target.value}/Add`)
    }
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer">
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }} className="innerHeader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h5" color="black">Media List</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="content">
                        <Typography variant="h6" sx={{ marginTop: 1, marginBottom: 1.5 }} color="black">Selected Screen: <span style={{ fontWeight: 400 }}>Screen 1</span> </Typography>
                        <FormControl>
                            <Select value={mediaType}
                                onChange={handleChangeMediaType}
                            >
                                <MenuItem sx={{ fontWeight: "600 !important" }} value="add">Add</MenuItem>
                                {items.map((item, index) => (
                                    <MenuItem sx={{ fontWeight: "600 !important" }} key={index} value={item.label}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            gap: '10px',
                            padding: '10px  0px 16px 0px',
                        }}>
                            {TileData.map((item, index) => (<Box key={index} sx={{
                                width: '48.5%', // Full width for the last item
                                height: '150px',
                                border: '1px solid #e0e0e0',
                                borderRadius: '8px',
                                backgroundColor: '#f8f8f8',
                            }}>
                                <Box sx={{ height: "calc(100% - 40px)", position: "relative" }}>
                                    <img src={item.media} alt={item.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    <Box sx={{ position: "absolute", bottom: 2, right: 1 }}>
                                        <IconButton onClick={() => { navigate(`/media/${item.type}/Edit`) }}>
                                            <BorderColorOutlinedIcon />
                                        </IconButton>
                                        <IconButton>
                                            <DeleteForeverOutlinedIcon sx={{ color: "red" }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '2px 4px', backgroundColor: "#fff" }}>
                                    <Typography variant='body2'> {item.name}</Typography>
                                    <IconButton>
                                        <MoreVertOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </Box>))}
                        </Box>


                    </Box>
                </Box>
            </Root>
        </DefaultLayout>
    )
}